import React, { useState, useEffect, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Divider } from '@mui/material';
import axios from "axios";
import { BASE_URL } from "../../Settings";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ChargeStationDataContext from './ChargeStationDataContext';

const ChargingStationGroups = (props) => {
    const token = Cookies.get("token");
    const ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    const { state } = useLocation();
    const [allGroups, setAllGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [unselectedGroups, setUnselectedGroups] = useState([]);
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);

    useEffect(() => {
        const api = axios.create({ baseURL: BASE_URL });

        const fetchData = async () => {
            try {
                const allGroupsData = await api.get(`UserGroup/GetUserGroups/${ownerGuid}/${token}`);
                const selectedGroupsData = await api.get(`UserGroup/StationGroups/${ownerGuid}/${chargeStationData.guid}/${token}`); //important!!

                const selectedGroupIds = selectedGroupsData.data.map(group => group.userGroupGuid);
                // Map group names from allGroupsData to selectedGroupsData
                const selectedGroupsWithNames = selectedGroupsData.data.map(group => {
                    const matchedGroup = allGroupsData.data.find(g => g.guid === group.userGroupGuid);
                    return { ...group, name: matchedGroup ? matchedGroup.name : "Unknown Group" };
                });

                setSelectedGroups(selectedGroupsWithNames);

                const unselectedGroupsData = allGroupsData.data.filter(group => !selectedGroupIds.includes(group.guid));
                setUnselectedGroups(unselectedGroupsData);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchData();
    }, [chargeStationData]);

    const handleRemoveGroup = async (group) => {
        try {
            const response = await axios.delete(
                `${BASE_URL}/UserGroup/RemoveCpLocation/${ownerGuid}/${group.userGroupGuid}/${chargeStationData.guid}/${token}` //important!!
            );

            console.log('RemoveCpGroup API response:', response.data);

            const updatedSelectedGroups = selectedGroups.filter(selectedGroup => selectedGroup.userGroupGuid !== group.userGroupGuid);
            setSelectedGroups(updatedSelectedGroups);

            setUnselectedGroups([...unselectedGroups, group]);
        } catch (error) {
            console.error('Error removing group:', error);
        }
    };

    const handleAddGroup = async (group) => {
        try {
            console.log("selectedStations in add satation", selectedGroups)
            console.log("unselectedStations", unselectedGroups)
            const isAlreadySelected = selectedGroups.some(selectedGroup => selectedGroup.userGroupGuid === group.userGroupGuid);

            if (!isAlreadySelected) {
                setSelectedGroups([...selectedGroups, group]);

                const updatedUnselectedGroups = unselectedGroups.filter(unselectedGroup => unselectedGroup.guid !== group.guid);
                setUnselectedGroups(updatedUnselectedGroups);

                const response = await axios.post(
                    `${BASE_URL}/UserGroup/AddCpLocation/${ownerGuid}/${group.guid}/${chargeStationData.guid}/${token}` //important!!
                );

                console.log('AddCpGroup API response:', response.data);
            }
        } catch (error) {
            console.error('Error adding group:', error);
        }
    };

    return (
        <div>
            <div style={{ width: "100%", border: "none", margin: '3% 0' }}>
                <Box component="div" sx={{ margin: '1% 2%' }}>
                    <Divider />
                    <TableContainer component={Paper}>
                        <Table >
                            <TableHead className="list-table-header" sx={{ backgroundColor: "#5faa39", border: "1px solid white", borderRadius: "1%" }}>
                                <TableRow>
                                    <TableCell sx={{ width: '40%', color: "white", borderRight: "1px solid white" }}>{t('Groups')}</TableCell>
                                    <TableCell sx={{ width: '30%', color: "white", borderRight: "1px solid white" }}>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ border: "1px solid white", borderRadius: "1%" }}>
                                {selectedGroups.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ borderBottom: "1px solid white", fontWeight: "bold", textAlign: "center" }}>
                                            <span style={{ color: 'red', fontWeight: 600 }}>{t('Add groups for this Charging Station.')}</span>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {selectedGroups.length > 0 && (
                                    <TableRow className="list-table-header">
                                        <TableCell colSpan={27} sx={{ borderBottom: "1px solid white", fontWeight: "bold", textAlign: "left" }}>{t('Selected Groups')}</TableCell>
                                    </TableRow>
                                )}
                                {console.log("selectedGroups", selectedGroups) }
                                {selectedGroups.map((group, index) => (
                                    <TableRow key={group.userGroupGuid}>
                                        <TableCell sx={{ borderRight: "1px solid white", backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                            {group.name}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: "1px solid white", textAlign: 'center', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                            <Typography
                                                variant="button"
                                                color="error"
                                                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                                                onClick={() => handleRemoveGroup(group)}
                                            >
                                                {t('remove')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow className="list-table-header">
                                    <TableCell colSpan={27} sx={{ borderBottom: "1px solid white", fontWeight: "bold", textAlign: "left" }}>{t('Unselected Groups')}</TableCell>
                                </TableRow>
                                {console.log("unselectedGroups", unselectedGroups)}
                                {unselectedGroups.map((group, index) => (
                                    <TableRow key={group.guid}>
                                        <TableCell sx={{ borderRight: "1px solid white", backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                            {group.name}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: "1px solid white", textAlign: 'center', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                            <Typography
                                                variant="button"
                                                color="primary"
                                                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                                                onClick={() => handleAddGroup(group)}
                                            >
                                                {t('add')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    );
};

export default ChargingStationGroups;
