
import React, { useState, useEffect } from 'react';
import revenue from "./Ladesitzungen.svg";
import "../../css/StationStatus.css";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import Cookies from 'js-cookie';
const ChargingSession = () => {
    var token = Cookies.get("token");
    // Create state to manage the collapsed state of each element
    const [isCollapsed, setIsCollapsed] = useState({});
    const [box1Data, setBox1Data] = useState([]);
    var guid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    useEffect(() => {
        let ownerGuid = Cookies.get("ownerGuid");
        // Fetch data for Box 1
        fetch(`${BASE_URL}/Booking/ChargingSessionsByCpo/${ownerGuid}/${token}`)
            .then(response => response.json())
            .then(data => setBox1Data(data));
    }, []);
    return (
         <Col lg="12" md="12" sm="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row className="d-flex align-items-center">
                            <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                    <img style={{ width: '85px' }} src={revenue} alt="revenue" />
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <div className="numbers">
                                <p className="card-category">{t('Charging Sessions')}</p>
                                    <CardTitle tag="p">{box1Data} </CardTitle>
                                    <p />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                       
                    </CardFooter>
                </Card>
            </Col>

    );
};

export default ChargingSession;
