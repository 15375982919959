
import React, { useState, useEffect, useContext } from 'react';
import { Divider, Col, Row, Switch } from 'antd';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { TextField, Box, FormControlLabel } from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core';
import axios from 'axios';

import ChargeStationDataContext from './ChargeStationDataContext';
import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import StateButton from '../Chargepoint/StateButton';

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            display: 'block',
        },
        select: {
            minWidth: 200,
        },
        greenUnderline: {
            '&:after': {
                borderBottomColor: '#4CAF50',
            },
        },
    })
);

function RoamingCockpit() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    
    const [values, setValues] = useState(null);

    // New state for the toggles
    const [isRoamingEnabled, setIsRoamingEnabled] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    useEffect(() => {
        if (chargeStationData) {
            setValues(chargeStationData);
            axios.get(`${BASE_URL}/Roaming/GetTermsAcceptanceFlag/${guid}/${chargeStationData.guid}/${token}`)
                .then((response) => {
                    const updatedData = response.data;
                    console.log("data of terms and acceptance", updatedData);
                    //setValues(updatedData);
                    //setChargeStationData(updatedData);
                    if (updatedData.tandCflag === 1) {
                        setIsTermsAccepted(true);
                    } else { setIsTermsAccepted(false); }

                    console.log("updatedData GetTermsAcceptanceFlag", updatedData);
                })
                .catch((error) => {
                    console.log(error);
                });
            axios.get(`${BASE_URL}/Roaming/GethubjectDataFromDb/${chargeStationData.guid}/${token}`)
                .then((response) => {
                    const updatedData = response.data;
                    console.log("GethubjectDataFromDbAsync", updatedData);
                    if (updatedData.roamingEnable=== 1) {
                        setIsRoamingEnabled(true);
                    } else { setIsRoamingEnabled(false); }
                    //setValues(updatedData);
                    //setChargeStationData(updatedData);
                   

                    console.log("updatedData GethubjectDataFromDbAsync", updatedData);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }, [chargeStationData]);

    const token = Cookies.get("token");
    const decoded = jwt_decode(token);
    const guid = Cookies.get("ownerGuid");


    const updateServer = async (isRoamingEnabled) => {
        try {
            if (isRoamingEnabled) {


                //// Wait for the Post request to complete
                //const createResponse = await axios.post(`${BASE_URL}/Roaming/CreateOrUpdateHubjectEvseIdSingle/${guid}/${chargeStationData.guid}/${token}`);
                //const createData = createResponse.data;
                //console.log("Data CreateOrUpdateHubjectEvseIdSingle", createData);

                //// Wait for the PUT request to complete
                //const enableResponse = await axios.put(`${BASE_URL}/Roaming/EnableHubjectEvseId/${guid}/${chargeStationData.guid}/${token}`);
                //const enableData = enableResponse.data;
                //console.log("Data EnableHubjectEvseId", enableData);
                // Wait for the first POST request to complete
                const pushResponse = await axios.post(`${BASE_URL}/Roaming/PushEvseDataInsert/${guid}/${chargeStationData.guid}/${token}`);
                const pushData = pushResponse.data;
                console.log("Data PushEvseDataInsert", pushData);

               
            } else {
                // Wait for the first POST request to complete
                const deleteResponse = await axios.post(`${BASE_URL}/Roaming/PushEvseDataDelete/${guid}/${chargeStationData.guid}/${token}`);
                const deleteData = deleteResponse.data;
                console.log("updatedData PushEvseDataDelete", deleteData);

                //// Wait for the PUT request to complete
                //const disableResponse = await axios.put(`${BASE_URL}/Roaming/DisableHubjectEvseId/${guid}/${chargeStationData.guid}/${token}`);
                //const disableData = disableResponse.data;
                //console.log("updatedData DisableHubjectEvseId", disableData);
            }
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };


   
   

    const [errors, setErrors] = useState({});
    

   

    
    const updateServerChargeStationtermsState = (isTermsAccepted) => {
       

        fetch(`${BASE_URL}/Roaming/UpdateTermsAcceptanceFlag/${guid}/${isTermsAccepted?1:0}/${token}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
          
        }).then((response) => {
            if (response.ok) {
                return response.json();

            } else {
                throw new Error('There was an error!');
            }
        }).then((updatedData) => {
            //setChargeStationData(updatedData);
            //console.log("updatedData in meter", updatedData);
        }).catch((error) => {
            console.error(error.message);
        });
    };

    //const handleBlur = () => {
    //    updateServerChargeStationtermsState();
    //};

    //const handleKeyDown = (event) => {
    //    if (event.key === 'Enter') {
    //        event.preventDefault();
    //        handleBlur();
    //    }
    //};

    const handleToggleRoaming = () => {
        if (isTermsAccepted) {
            setIsRoamingEnabled(!isRoamingEnabled);
            updateServer(!isRoamingEnabled)
        }
       
    };

    const handleToggleTerms = () => {
        setIsTermsAccepted(!isTermsAccepted);
        updateServerChargeStationtermsState(!isTermsAccepted);
    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <Box component="div">
                    <div>
                        <form>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    flexDirection: 'column', // Stack items vertically
                                    gap: '16px', // Add space between switches
                                }}
                            >
                                <Col sx={24} md={10} style={{ width: '100%' }}>
                                    {/* Roaming toggle */}
                                    <div style={{ marginBottom: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={isRoamingEnabled}
                                                    onChange={handleToggleRoaming}
                                                />
                                            }
                                            label={t('Roaming')}
                                            disabled={!isTermsAccepted}
                                            style={{ margin: '8px 0', display: 'flex', alignItems: 'center' }}
                                            componentsProps={{
                                                typography: { style: { marginLeft: '8px' } }
                                            }}
                                        />
                                    </div>
                                    {/* Accept Terms toggle */}
                                    {!isTermsAccepted ? (
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={isTermsAccepted}
                                                        onChange={handleToggleTerms}
                                                    />
                                                }
                                                label={
                                                    <>
                                                        {t('Terms read and accepted')}
                                                    </>
                                                }
                                                style={{ margin: '8px 0', display: 'flex', alignItems: 'center', gap: '8px' }}
                                            />
                                        </div>
                                                                            ) : (
                                            <h6 style={{ marginTop: '16px' }}>{t('Terms read and accepted')}</h6>
                                    )}
                                </Col>
                                <Col sx={24} md={10} style={{ width: '100%' }}>
                                    <p>
                                        {t('Enable the use of your station through apps or charging cards from other e-mobility providers (EMPs). The end-customer price per kWh, as well as any additional fees, are set by the respective EMP. List of currently supported EMPs.') }
                                    </p>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('For electricity sold via roaming, we pay a fixed amount of {{amount}} (gross). Billing and payout take place in the following month.', {
                                                amount: '<strong>40 ct/kWh</strong>',
                                                interpolation: { escapeValue: false },
                                            }),
                                        }}
                                    />

                                </Col>
                                {/* Additional error handling */}
                            </div>

                        </form>
                    </div>
                </Box>
            </div>
        </>
    );
}

export default RoamingCockpit;
