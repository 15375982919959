import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../Settings';
import { useNavigate } from 'react-router-dom';

function ServiceFeeReports() {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
    var guid = decoded.jti;
       var ownerGuid = Cookies.get("ownerGuid");
    const { t, i18n } = useTranslation(); // Using i18next for translation
    const currentLocale = i18n.language;
    useEffect(() => {
        axios
            .get(`${BASE_URL}/Invoice/InvoicesByCpo/${ownerGuid}/${token}`)
            .then(function (response) {
                console.log("bookings", response.data);
                setRows(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    // Function to format amount and vat according to the current locale
    const formatNumber = (number) => {
        const locale = currentLocale === 'de' ? 'de-DE' : 'en-US';
        return number.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    return (
        <Box component="div" sx={{ margin: "5% 10%", borderColor: "white" }}>
            <TableContainer component={Paper} sx={{ borderColor: "white" }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead sx={{ border: "none" }}>
                        <TableRow sx={{ border: "none" }}>
                            {/* <TableCell sx={{ border: "none" }}>Fahre</TableCell> */}
                            <TableCell sx={{ border: "none" }} align="right">{t('Year')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Month')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Amount')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Vat')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('pdf')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow
                                    key={row.name}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="right" sx={{ border: "none" }}>{row.year}</TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>{row.month}</TableCell>

                                    <TableCell align="right" sx={{ border: "none" }}>
                                        {formatNumber(row.amount)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>
                                        {formatNumber(row.vat)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>
                                        <Link
                                            to="/serviceFeesReports"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = row.link; // Navigate to actual URL
                                            }}
                                            title="Download File" // Custom text for status bar
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        >
                                            Download
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ServiceFeeReports;
